const STATUS = {
  LOADING: "loading",
  READY: "ready",
  ERROR: "error",
  VALIDATION: "validation",
};

const CONSTRAINTS = {
  video: {
    width: { min: 200, ideal: 300, max: 500 },
    height: { min: 200, ideal: 300, max: 500 },
    facingMode: {
      exact: "environment",
    },
  },
  audio: false,
};

const CAMERA = {
  STATUS,
  CONSTRAINTS,
};

export default CAMERA;
